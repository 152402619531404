.layout {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right top, #ffffff, #fafbfc, #f3f7f7, #eef3f1, #eceee9);
}

.container {
    width: calc(100% - 300px);
    min-height: 100vh;
    position: relative;
}


.content {
    padding: 0;
}


/* Content TOP Styles */
.content-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    height: 85px;
}

.content-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content-name img {
    width: 30px;
    height: 30px;
    opacity: 0.7;
}

.content-name p {
    font-size: 30px;
    font-weight: 900;
    padding-left: 6px;
}

.content-name span {
    display: block;
    padding: 4px 8px;
    background-color: var(--primary);
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 7px;
    color: white;
    border-radius: 20px;
    font-weight: 1000;
}

.content-name span.hide {
    opacity: 0;
}

.content-create {
    margin-top: 5px;
    margin-left: 30px;
}

.content-create-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    height: 40px;
    width: 100px;
    border-radius: 20px;
    color: white;
    font-size: 13px;
    cursor: pointer;
    font-weight: 1000;
    transition: transform 0.5s;
}

.content-create-button:hover {
    transform: scale(1.05);
}

.content-create-button.green {
    width: 150px;
    background-color: var(--primary);
}

.content-search {
    margin-top: 5px;
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.content-search img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    position: absolute;
    left: 16px;
    opacity: 0.5;
}

.content-search input {
    width: 240px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0 0 20px #f5f5f5;
    border: none;
    outline: none;
    padding-left: 36px;
    padding-top: 2px;
    padding-right: 10px;
    background: white;
}







/* Content box styles */
.content-box {
    background: white;
    border-radius: 20px;
    box-shadow: 0 0 20px #f5f5f5;
    padding: 20px;
    margin: 0 20px;
}

.content-data-box {
    min-height: 400px
}

.content-box-head {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}

.content-head {
    width: 20%;
    padding: 4px;
    font-weight: 1000;
    font-size: 13px;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-box-body {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}

.content-item {
    width: 20%;
    padding: 4px;
    font-weight: 300;
    font-size: 15px;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}

.content-item img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.content-item img.ava {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.number {
    width: 10%;
}

.name {
    width: 30%;
}

.content-layout-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.content-layout-content p {
    font-size: 14px;
    margin: 5px 0
}

/* Specify style for ORDER page */
.content-data-box.order .content-item,
.content-box-head.order .content-head {
    width: 16.6%;
}

.content-data-box.order .content-item.number,
.content-box-head.order .content-head.number {
    width: 6.6%;
}

.content-data-box.order .content-item.name,
.content-box-head.order .content-head.name {
    width: 26.6%;
}

.content-data-box.order .content-item.blue {
    font-weight: 900;
    color: #2196f3;
    cursor: pointer;
}


/* Content Element styles */
.content-element {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-element span {
    font-size: 30px;
}





/* 
    CONTENT BUTTON STYLES 
*/
.content-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-item-button {
    background: black;
    color: white;
    margin-left: 10px;
    padding: 8px 16px;
    font-weight: 1000;
    font-size: 12px;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.5s;
    text-decoration: none;
}

.content-item-button:hover {
    transform: scale(1.05);
}

.content-item-button.delete {
    background-color: #f44336;
}

.content-item-button.green {
    background-color: var(--primary);
}




/* Tag styles */
.tag {
    background: black;
    color: white;
    font-size: 12px;
    font-weight: 1000;
    padding: 4px 10px;
    border-radius: 20px;
    text-align: center;
}

.tag.green {
    background: #8bc34a;
}

.tag.yellow {
    background: #ffc107;
}

.tag.whiteblue {
    background: #4fc3f7;
}

.tag.blue {
    background: #2196f3;
}

.tag.darkgreen {
    background: #4caf50;
}

.tag.purple {
    background: #3f51b5;
}

.tag.darkblue {
    background: #0D47A1;
}

.tag.red {
    background: #f44336;
}

.tag.pink {
    background: #e91e63;
}







/* CONTENT FORM STYLES */
.content-form-box {
    display: flex;
    justify-content: center;
}

.content-form-half {
    width: 50%;
    padding-right: 30px;
}

.content-form-half h2 {
    font-weight: 300;
    font-size: 22px;
    margin-bottom: 20px;
}

.content-form-half p {
    font-size: 14px;
    font-weight: 800;
    padding-bottom: 3px;
}


.content-form-half input[type="text"] {
    width: 100%;
    height: 44px;
    padding: 10px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    outline: none;
    margin-bottom: 15px;
}

.content-space {
    height: 10px;
}

.content-input-file {
    position: relative;
    display: inline-block;
    outline: none;
    margin-top: 3px;
}

.content-input-file-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 13px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 6px;
    background-color: #202020;
    height: 40px;
    padding: 10px 20px;
    line-height: 22px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: transform 0.5s;
    font-weight: 800;
}

.content-input-file-text {
    padding: 0 10px;
    line-height: 40px;
    display: inline-block;
    font-size: 12px;
    color: #666;
}

.content-input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

/* Focus */
.content-input-file input[type=file]:focus+.content-input-file-button {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover/active */
.content-input-file:hover .content-input-file-button {
    transform: scale(1.05);
}

/* Disabled */
.content-input-file input[type=file]:disabled+.content-input-file-button {
    background-color: #eee;
}



.content-form-half select {
    width: 100%;
    height: 44px;
    padding: 10px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    outline: none;
    margin-bottom: 15px;
}

.content-form-half textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    outline: none;
    margin-bottom: 15px;
}





.content-form-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-form-button {
    height: 50px;
    width: 170px;
    margin-top: 30px;
    background: #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 1000;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 0.5s;
    font-size: 14px;
    box-shadow: 0 0 40px #D9D9D9;
    border: none;
    outline: none;
}

.content-form-button:hover {
    transform: scale(1.05);
}







/* CONTENT GALLERY */
.content-gallery-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}

.content-gallery-file {
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
}

.content-gallery-button {
    border: 2px dashed #666;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content-gallery-button img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}


.content-gallery-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

.content-gallery-item {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    margin-right: 10px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}

.content-gallery-item img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
}

.content-gallery-remove {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    box-shadow: 0 0 15px #999;
    background: #f44336;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content-gallery-remove img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}





/* Characteristics */
.characteristics {
    margin-bottom: 30px;
}

.content-empty-characteristics {
    font-size: 12px;
    color: #666
}

.content-characteristics-button {
    height: 34px;
    width: 100px;
    background: black;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    transition: transform 0.5s;
    font-size: 13px;
    margin-top: 10px;
}

.content-characteristics-button:hover {
    transform: scale(1.05);
}


.swal2-content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.swal2-content-select {
    width: 255px;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .06), 0 0 0 3px rgba(0, 0, 0, 0);
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
}

.swal2-content-select-label {
    font-size: 14px;
    font-weight: 700;
    width: 255px;
    display: flex;
    padding-bottom: 3px;
}


.content-characteristics-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2px 0
}

.content-characteristics-item-name {
    font-size: 14px;
    font-weight: 800;
}

.content-characteristics-item-value {
    font-size: 14px;
    margin-left: 40px;
}

.content-characteristics-item-remove {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #f44336;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;
}

.content-characteristics-item-remove img {
    width: 15px;
    height: 15px;
    object-fit: contain;
}




.content-item-review {
    display: flex;
    flex-direction: column;
}

.content-item-review p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    margin: 2px 0
}

.content-item-review p b {
    margin-right: 6px;
}

.review-rate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 800;
}

.review-rate img {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    margin-top: -2px;
}



.switch {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
}

.switch p {
    width: 200px;
}