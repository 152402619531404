.dashboard-welcome {
    width: 40%;
    background: white;
    border-radius: 20px;
    box-shadow: 0 0 20px #f5f5f5;
    padding: 20px;
    margin: 0 20px;
}

.dashboard-welcome h2 {
    font-size: 20px;
    font-weight: 200;   
}

.dashboard-welcome h2 b {
    font-weight: 800;
}

.dashboard-welcome p {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #999;
}