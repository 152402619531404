/* ----------- */
/* Menu Styles */
/* ----------- */
.menu {
    width: 300px;
}


/* Menu Logo Styles */
.menu-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.menu-logo img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.menu-logo h2 {
    font-weight: 100;
    margin-left: 10px;
    font-size: 24px;
    letter-spacing: 4px;
}



/* Menu Item Styles */
.menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
}

.menu-dot {
    background: transparent;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 12px;
    margin-left: 5px;
    margin-top: 2px;
}

.menu-item.active .menu-dot {
    background: var(--primary);
    box-shadow: 0 0 10px var(--primary);
}

.menu-item img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    opacity: 0.7;
}

.menu-item img.bigger {
    width: 24px;
    height: 24px;
    margin-left: -2px;
}

.menu-item.active img {
    opacity: 1;
}

.menu-name {
    color: #4d4e55;
    margin-top: 2px;
    font-weight: 500;
    margin-left: 8px;
    font-size: 15px;
}

.menu-item.active .menu-name {
    color: #000;
    font-weight: 900;
}

.menu-space {
    padding-left: 41px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 5px;
    color: #666
}

