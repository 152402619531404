:root {
    --primary: #4caf50;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
}

html,
body,
#root,
.app {
    height: 100vh;
}

a {
    text-decoration: none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}